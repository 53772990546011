import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Wrapper from "../components/wrapper"
import SectionTitle from "../components/sectionTitle"
import text from "../constants/labels.json"
import PlaceCard from "../components/placeCard"
import chacabuco from "../images/chacabuco.jpeg"
import museoMejillones from "../images/museoMejillones.jpg"
import museoAntofagasta from "../images/museoAntofagasta.jpg"
import museoRuinas from "../images/museoRuinas.jpeg"


const Historia = () => (
  <Layout>
    <Seo title="Historia" />
    <Background>
      {" "}
      <Wrapper>
        <Container>
          <SectionTitle title={text.labels.pages.history} textColor="#26272d" />
          <PlaceTitle>Estos son los lugares a visitar:</PlaceTitle>
          <SectionContainer>
            <InfoSection>
              <PlaceCard
                picSrc={chacabuco}
                cardColor="#e7b84c"
                title={text.labels.info.places.history.places.oficina.title}
                placeInfo={
                  text.labels.info.places.history.places.oficina.description
                }
                placeLink={text.labels.info.places.history.places.oficina.link}
                buttonTextColor="#f5f4f4"
                buttonBgColor="#6fa73b"
                buttonHoverColor="#7eb54a"
                buttonActiveColor="#588a2b"
                isRight={false}
              />
              <PlaceCard
                picSrc={museoMejillones}
                cardColor="#e7b84c"
                title={
                  text.labels.info.places.history.places.museoMejillones.title
                }
                placeInfo={
                  text.labels.info.places.history.places.museoMejillones
                    .description
                }
                placeLink={
                  text.labels.info.places.history.places.museoMejillones.link
                }
                buttonTextColor="#f5f4f4"
                buttonBgColor="#6fa73b"
                buttonHoverColor="#7eb54a"
                buttonActiveColor="#588a2b"
                isRight={true}
              />
              <PlaceCard
                picSrc={museoAntofagasta}
                cardColor="#e7b84c"
                title={
                  text.labels.info.places.history.places.museoAntofagasta.title
                }
                placeInfo={
                  text.labels.info.places.history.places.museoAntofagasta
                    .description
                }
                placeLink={
                  text.labels.info.places.history.places.museoAntofagasta.link
                }
                buttonTextColor="#f5f4f4"
                buttonBgColor="#6fa73b"
                buttonHoverColor="#7eb54a"
                buttonActiveColor="#588a2b"
                isRight={false}
              />
              <PlaceCard
                picSrc={museoRuinas}
                cardColor="#e7b84c"
                title={text.labels.info.places.history.places.museoRuinas.title}
                placeInfo={
                  text.labels.info.places.history.places.museoRuinas.description
                }
                placeLink={
                  text.labels.info.places.history.places.museoRuinas.link
                }
                buttonTextColor="#f5f4f4"
                buttonBgColor="#6fa73b"
                buttonHoverColor="#7eb54a"
                buttonActiveColor="#588a2b"
                isRight={true}
              />
              <StyledLink to="/">Volver a la página principal</StyledLink>
            </InfoSection>
          </SectionContainer>
        </Container>
      </Wrapper>
    </Background>
  </Layout>
)

const Container = styled.div`
  margin-top: 200px;
  @media screen and (max-width: 770px) {
    margin-top: 50px;
  }
`
const PlaceTitle = styled.h3`
  margin-top: 45px;
  text-align: center;
`
const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 650px;
  font-size: 1.15rem;
  line-height: 2rem;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`
const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`
const StyledLink = styled(Link)`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  text-decoration: none;
`
const Background = styled.div`
  background-color: #fcecc9;
  padding-top: 50px;
`

export default Historia
