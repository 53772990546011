import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const PlaceCard = ({
  picSrc,
  cardColor,
  title,
  placeInfo,
  placeLink,
  buttonBgColor,
  buttonTextColor,
  buttonHoverColor,
  buttonActiveColor,
  isRight,
}) => {
  return (
    <>
      <CardContainer cardColor={cardColor}>
        {isRight && <Pic src={picSrc} alt="Imagen lugar a visitar" />}
        <InfoContainer>
          <CardTitle>{title}</CardTitle>
          <InfoText>{placeInfo}</InfoText>
          <StyledLink
            href={placeLink}
            target="_blank"
            rel="noreferrer"
            buttontextcolor={buttonTextColor}
            buttonbgcolor={buttonBgColor}
            buttonHoverColor={buttonHoverColor}
            buttonActiveColor={buttonActiveColor}
          >
            Más info
          </StyledLink>
        </InfoContainer>
        {!isRight && <Pic src={picSrc} alt="Imagen lugar a visitar" />}
      </CardContainer>
    </>
  )
}
const CardContainer = styled.div`
  position: relative;
  padding: 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  width: 900px;
  background-color: ${props => props.cardColor};
  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`

const CardTitle = styled.h1`
  color: #f5f4f4;
`
const Pic = styled.img`
  max-width: 200px;
  margin-top: -40px;
  border: 4px solid #f5f4f4;
  border-radius: 20px;
  @media screen and (max-width: 900px) {
    margin-top: 20px;
  }
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f5f4f4;
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`
const InfoText = styled.p`
  font-size: 1.05rem;
  text-align: center;
  padding: 10px;
`

const StyledLink = styled.a`
  position: relative;
  transition: all 250ms;
  margin-top: 10px;
  text-align: center;
  color: ${props => props.buttontextcolor};
  background-color: ${props => props.buttonbgcolor};
  width: 200px;
  padding: 20px;
  font-weight: bold;
  font-size: 1.1rem;
  border-radius: 8px;
  border: none;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;

  &:hover,
  focus {
    background-color: ${props => props.buttonHoverColor};
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  }

  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    background-color: ${props => props.buttonActiveColor};
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    transform: translateY(0);
  }
`

PlaceCard.propTypes = {
  picSrc: PropTypes.string.isRequired,
  cardColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  placeLink: PropTypes.string.isRequired,
  placeInfo: PropTypes.string.isRequired,
  buttonBgColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonActiveColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
}

export default PlaceCard
